.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(89.09deg, #FAEC60 -4.93%, #E5A34B 8.69%, #EE6E4D 20.84%, #F44380 37.75%, #BE3DB3 52.82%, #7746E6 73.27%, #5A71F1 89.06%, #439EFF 101.36%);
    color: #fff;
    padding: 15px;
    font-size: 14px;
    z-index: 1000;
  }
  
  .cookie-message {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .cookie-buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #4caf50;
    border: none;
    color: white;
    cursor: pointer;
    width: 120px;
    border-radius: 999px;
    background-color: #160042;
  }
  