@media (min-width: 768px) {
  .DocRequestHistoryDrawer .ant-drawer-content-wrapper {
    width: 80vw !important;
  }
}

@media (min-width: 1024px) {
  .DocRequestHistoryDrawer .ant-drawer-content-wrapper {
    width: 50vw !important;
  }
}

.DocRequestHistoryDrawer .ant-drawer-body {
  padding: 0px 16px;
  background-color: #eef4fb !important;
}

.DocRequestHistoryDrawer .ant-drawer-header {
  padding: 16px;
  border-bottom: 0 !important;
  background-color: #eef4fb !important;
}

.DocRequestHistoryDrawer .ant-drawer-footer {
  padding: 16px;
  border-top: 0 !important;
  background-color: #eef4fb !important;
}
