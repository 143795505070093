.AiReportViewerDrawer .ant-drawer-content-wrapper {
  width: 100vw !important;
}

@media (min-width: 768px) {
  .AiReportViewerDrawer .ant-drawer-content-wrapper {
    width: 99vw !important;
  }
}

@media (min-width: 1024px) {
  .AiReportViewerDrawer .ant-drawer-content-wrapper {
    width: 98vw !important;
  }
}

.AiReportViewerDrawer .ant-drawer-body {
  padding: 0px !important;
}

.copy-text-btn {
  margin-left: 8px;
  color: #007aea;
  cursor: pointer;
}
